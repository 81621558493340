import React from 'react'
import PropTypes from 'prop-types'
import { graphql, Link } from 'gatsby'
import Helmet from 'react-helmet'

import { Tags } from '@tryghost/helpers-gatsby'
import { Layout, SuggestedPost } from '../components/common'
import { MetaData } from '../components/common/meta'

/**
 * Single post view (/:slug)
 *
 * This file renders a single post and loads all the content.
 *
 */
const Post = ({ data, location }) => {
  const post = data.ghostPost
  const url = `author/${data.ghostPost.authors[0].slug}/`
  const nextPost = data.allGhostPost.edges
  
  return (
    <>
    <MetaData
      data={data}
      location={location}
      type="article"
    />
    <Helmet>
      <style type="text/css">{`${post.codeinjection_styles}`}</style>
    </Helmet>
    <Layout isPost={true}>
      <div className="container">
        <article className="content">
          <h2 className="text-center text-primary">{post.created_at_pretty} {post.primary_tag? "/ " + post.primary_tag.name : null } </h2>
          <h1 className="content-title text-center">{post.title}</h1>
          { post.feature_image ?
              <figure className="post-feature-image">
                <img src={ post.feature_image } alt={ post.title } />
              </figure> : null }
              <section className="post-full-content">
                {/* The main post content */ }
                <section
                  className="content-body load-external-scripts"
                  dangerouslySetInnerHTML={{ __html: post.html }}
                />
                <footer className="post-card-footer">
                  <div className="post-card-footer-left">
                    <div className="post-card-avatar">
                      {post.primary_author.profile_image ?
                          <img className="author-profile-image" src={post.primary_author.profile_image} alt={post.primary_author.name}/> :
                          <img className="default-avatar" src="/images/icons/avatar.svg" alt={post.primary_author.name}/>
                      }
                    </div>
                    <span>{ post.primary_author.name }</span>
                  </div>
                  <div className="post-card-footer-right">
                    <Link to={url} className="btn btn-light-rounded">Read more</Link>
                  </div>
                </footer>
              </section>
            </article>
          </div>
          <section className="bg-light mt-120">
            <div className="container">
              <section className="post-feed view-post mt-n110 py-5">
                {nextPost.map(({ node }) => (
                  // The SuggestedPost  - components/common/SuggestedPost.js
                  <SuggestedPost key={node.id} post={node} />
                ))}
              </section>
            </div>        
          </section>
      </Layout>
    </>
  )
}

Post.propTypes = {
  data: PropTypes.shape({
    ghostPost: PropTypes.shape({
      codeinjection_styles: PropTypes.object,
      title: PropTypes.string.isRequired,
      html: PropTypes.string.isRequired,
      feature_image: PropTypes.string,
    }).isRequired,
    allGhostPost: PropTypes.shape({
      slug: PropTypes.string.isRequired,
      title: PropTypes.string.isRequired,
      excerpt: PropTypes.string.isRequired,
      feature_image: PropTypes.string,
      published_at: PropTypes.string.isRequired,
      primary_author: PropTypes.shape({
        name: PropTypes.string.isRequired,
        profile_image: PropTypes.string,
      }).isRequired,
      tags: PropTypes.arrayOf(
        PropTypes.shape({
          name: PropTypes.string,
        })
      ),
    }).isRequired
  }).isRequired,
  location: PropTypes.object.isRequired,
}

export default Post

export const postQuery = graphql`
    query($slug: String!) {
        ghostPost(slug: { eq: $slug }) {
            ...GhostPostFields
        }
        allGhostPost(limit: 1) {
          edges {
            node {
              id
              slug
              title
              excerpt
              feature_image
              published_at(formatString: "ll")
              primary_author {
                name
                profile_image
              }
              tags {
                name
              }
            }
          }
        }
    }
`



